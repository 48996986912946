<template>
  <div>

    <div class="row-layout">

      <span v-if="showDpt">
        DPT
        <el-select class="input-item" size="mini" v-model="address.dpt" :disabled="disabledDpt" placeholder="DPT" @blur="onBlurDpt" @change="onBlurDpt">
          <el-option v-for="dpt in dptValues" :label="dpt.title" :value="dpt.value" :key="dpt.value">
          </el-option>
        </el-select>
      </span>

      <span v-if="showWriteAddress">
        Write
        <el-input class="input-item" size="mini" type="text" v-model="address.writeAddress" placeholder=".../.../..." v-mask="groupAddressMask" @blur="onBlurWriteAddress" @change="onBlurWriteAddress"></el-input>
      </span>


      <span v-if="showReadAddress">
        Read
        <el-input class="input-item" size="mini" type="text" v-model="address.readAddress" placeholder=".../.../..." v-mask="groupAddressMask" @blur="onBlurReadAddress" @change="onBlurReadAddress"></el-input>
      </span>

    </div>

    <div class="row-layout">

      <span v-if="showWriteValue">
        Write Value
        <el-input class="input-item" size="mini" type="text" v-model="address.writeValue" v-mask="maskDecimal" placeholder="Write Value" @blur="onBlurWriteValue" @change="onBlurWriteValue"></el-input>
      </span>



      <span v-if="showActiveValue">
        Active Value
        <el-input class="input-item" size="mini" type="text" v-model="address.activeValue" v-mask="maskDecimal" placeholder="Active Value" @blur="onBlurActiveValue" @change="onBlurActiveValue"></el-input>
      </span>

    </div>


  </div>
</template>

<script>
  import {knxMixin} from "./knxMixin";

  export default {
    name: "KnxGroupAddress",

    mixins: [knxMixin],

    props: {
      address: {},
      dptValues: {
        type: Array,
        default: undefined
      },
      enableWriteAddress: {
        type: Boolean,
        default: true
      },
      enableReadAddress: {
        type: Boolean,
        default: true
      },
      enableDpt: {
        type: Boolean,
        default: true
      },
      disabledDpt: {
        type: Boolean,
        default: false
      },
      enableWriteValue: {
        type: Boolean,
        default: true
      },
      enableActiveValue: {
        type: Boolean,
        default: true
      },
    },

    computed: {
      showWriteAddress(){
        return this.enableWriteAddress
      },
      showDpt(){
        return this.enableDpt
      },
      showWriteValue(){
        return this.enableWriteValue
      },
      showReadAddress(){
        return this.enableReadAddress
      },
      showActiveValue(){
        return this.enableActiveValue
      },

      /*validateFields(){
        console.log("KnxGroupAddress validate")

        var msg = []
        if ( (this.showReadAddress && !this.validateKnxGroupAddress(this.address.readAddress))
          || (this.showWriteAddress && !this.validateKnxGroupAddress(this.address.writeAddress)) ) {
          msg.push(this.$t('accessory.form-rules.knx-address-invalid'))
        }
        if (this.showDpt && !this.address.dpt) {
          msg.push(this.$t('accessory.form-rules.dpt-empty'))
        }
        if (this.showActiveValue && !this.validateKnxValue(this.address.dpt, this.address.activeValue)) {
          msg.push(this.$t('accessory.form-rules.active-value-invalid'))
        }
        if (this.showWriteValue && !this.validateKnxValue(this.address.dpt, this.address.writeValue)) {
          msg.push(this.$t('accessory.form-rules.write-value-invalid'))
        }

        if (msg.length > 0) {
          const msgStr = msg.join(", ")
          this.$message({ type: "error", message: "[" + this.address.variable + "] " + msgStr + "<br>", dangerouslyUseHTMLString: true})
          return false
        } else {
          return true
        }
      }*/
    },


    watch: {
      address: {
        deep: true,
        immediate: true, 
        handler: function (val) {
          console.log("address", val)

          this.$emit("changeKnxAddress", this.address.variable, this.getValidationMessage())
        }
      }
    },


    methods: {
      validateFields(){
        //console.log("KnxGroupAddress validate")

        var msg = []
        if ( (this.showReadAddress && !this.validateKnxGroupAddress(this.address.readAddress))
          || (this.showWriteAddress && !this.validateKnxGroupAddress(this.address.writeAddress)) ) {
          msg.push(this.$t('accessory.form-rules.knx-address-invalid'))
        }
        if (this.showDpt && !this.address.dpt) {
          msg.push(this.$t('accessory.form-rules.dpt-empty'))
        }
        if (this.showActiveValue && !this.validateKnxValue(this.address.dpt, this.address.activeValue)) {
          msg.push(this.$t('accessory.form-rules.active-value-invalid'))
        }
        if (this.showWriteValue && !this.validateKnxValue(this.address.dpt, this.address.writeValue)) {
          msg.push(this.$t('accessory.form-rules.write-value-invalid'))
        }

        if (msg.length > 0) {
          const msgStr = msg.join(", ")
          this.$message({ type: "error", message: "[" + this.address.variable + "] " + msgStr})
          return false
        } else {
          return true
        }
      },

      getValidationMessage(){
        var msg = []
        if ( (this.showReadAddress && !this.validateKnxGroupAddress(this.address.readAddress))
          || (this.showWriteAddress && !this.validateKnxGroupAddress(this.address.writeAddress)) ) {
          msg.push(this.$t('accessory.form-rules.knx-address-invalid'))
        }
        if (this.showDpt && !this.address.dpt) {
          msg.push(this.$t('accessory.form-rules.dpt-empty'))
        }
        if (this.showActiveValue && !this.validateKnxValue(this.address.dpt, this.address.activeValue)) {
          msg.push(this.$t('accessory.form-rules.active-value-invalid'))
        }
        if (this.showWriteValue && !this.validateKnxValue(this.address.dpt, this.address.writeValue)) {
          msg.push(this.$t('accessory.form-rules.write-value-invalid'))
        }


        return msg.length > 0 ? ("[" + this.address.variable + "] " + msg.join(", ")) : ""
      },

      onBlurDpt(){
        //this.$emit("changeKnxAddress", this.address.variable, this.getValidationMessage())
      },
      onBlurWriteAddress(){
        //this.$emit("changeKnxAddress", this.address.variable, this.getValidationMessage())
      },
      onBlurReadAddress(){
        //this.$emit("changeKnxAddress", this.address.variable, this.getValidationMessage())
      },
      onBlurWriteValue(){
        //this.$emit("changeKnxAddress", this.address.variable, this.getValidationMessage())
      },
      onBlurActiveValue(){
        //this.$emit("changeKnxAddress", this.address.variable, this.getValidationMessage())
      }
    },


    created(){
      if (this.dptValues == undefined)
        this.dptValues = this.dataPoints
    }
  }
</script>

<style scoped>

  .input-item {
    width: 160px;
    margin-left: 10px;
    margin-right: 20px
  }

  .row-layout {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    margin-top: 16px;
  }
</style>